<template>
  <v-card class="card-shadow">
    <div class="card-header-padding card-border-bottom">
      <p class="font-weight-600 text-h3 text-typo mb-0">Active devices</p>
    </div>
    <v-card-text class="px-0 py-0">
      <v-simple-table class="table">
        <template v-slot:default>
          <thead class="thead-light">
            <tr>
              <th class="text-left pl-8">Device</th>
              <th class="text-right">Earned</th>
              <th class="text-right pr-16">Shared Traffic</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(device) in devices" :key="device.id">
              <td class="border-bottom pl-6">
                <v-list class="py-0">
                  <v-list-item class="px-0 py-4">
                    <div class="my-0 me-5">
                      <img width="48" height="48" v-if="isAndroidPlatform(device.platform, device.name)" :alt="device.platform" :src="androidImg">
                      <img width="48" height="48" v-if="isWindowsPlatform(device.platform, device.name)" :alt="device.platform" :src="windowsImg">
                    </div>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-body text-subtitle-2 ls-0 font-weight-600"
                        >{{ truncateDeviceNameIfNecessary(device.name) }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </td>
              <td class="text-body text-right border-bottom text-h5">
                {{ formatCurrency7(device.amount) }}
              </td>
              <td class="text-body text-right border-bottom text-h5 pr-16">
                <div class="d-flex justify-end">
                  {{ formatTraffic(device.traffic) }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "devices-table",
  props: ['devices'],
  data() {
    console.log('Devices are', this.devices);
    return {
      androidImg: require("@/assets/img/android.svg"),
      appleImg: require("@/assets/img/apple.svg"),
      windowsImg: require("@/assets/img/windows.svg"),
    };
  },
  methods: {
    formatCurrency7(value) {
      if (value == 0) return `$ 0.0000000`;
      return `$ ${value.toFixed(7)}`;
    },
    formatTraffic(value) {
      if (value > 1024 * 1024 * 1024) return `${(value / 1024 / 1024 / 1024).toFixed(0)} GB`;
      if (value > 1024 * 1024) return `${(value / 1024 / 1024).toFixed(0)} MB`;
      if (value > 1024) return `${(value / 1024).toFixed(0)} KB`;
      return `${value.toFixed(0)} B`;
    },
    truncateDeviceNameIfNecessary(name) {
      //Device names on linux desktop App are quite long and break table layout
      //MAX_LEN characters just a reasonable number to work with
      //TODO: maybe redesign this card or change devices names
      const MAX_LEN = 17;
      return name.substr(0, MAX_LEN);
    },
    isAndroidPlatform(platform, name) {
      let result = platform == 'android' && !this.isWindowsPlatform(platform, name);
      return result;
    },
    isWindowsPlatform(platform, name) {
      let result = platform == 'windows' || name.toLowerCase().indexOf('windows') != -1;
      return result;
    },
  },
};
</script>
