<template>
  <div>
    <v-container fluid class="pa-6">
      <v-row>
        <v-col cols="12">
          <h1 class="title-main py-2">Dashboard</h1>
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-card class="card-shadow mb-6 card-balance">
            <div class="card-header-padding card-border-bottom">
              <v-card-title class="pa-0 text-h3 font-weight-600 text-typo">
                Balance
              </v-card-title>
            </div>
            <v-card-text class="pt-8 px-10 pb-12 card-balance__content">
              <p class="card-balance__output mx-auto mt-3 mb-9 py-3 px-4 rounded-lg">
                {{formatCurrency7(userinfo.balance)}}
              </p>
              <ul class="card-balance__list px-3 pb-10">
                <li>
                  <span class="card-balance__list-numbers pb-2">{{formatCurrency7(userinfo.earnedToday)}}</span>
                  <span class="card-balance__list-caption">Earned today</span>
                </li>
                <li>
                  <span class="card-balance__list-numbers pb-2">{{formatTraffic(userinfo.trafficToday)}}</span>
                  <span class="card-balance__list-caption">Shared today</span>
                </li>
                <li>
                  <span class="card-balance__list-numbers pb-2">{{formatCurrency7(userinfo.earnedTotal)}}</span>
                  <span class="card-balance__list-caption">Lifetime Earnings</span>
                </li>
              </ul>
              <div class="d-flex justify-center align-center">
                <template>
                  <div class="text-center">
                    <v-dialog
                      v-model="dialog"
                      width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="text-transform-none"
                          color="#43A4FF"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          Request Payout
                        </v-btn>
                      </template>

                      <v-card class="card-payout">
                        <v-card-title class="text-h2 font-weight-600 py-5 px-6 text-main lighten-2">
                          Request payout
                        </v-card-title>
                        <v-divider></v-divider>
                        <div class="pa-6">
                          <p class="text-center balance-output text-main mx-auto my-0"> {{formatCurrency2(userinfo.balance)}} </p>
                          <p class="balance-output__label mb-3 text-center">Balance</p>
                          <p class="text-center balance-output__text">Minimum amount required to withdraw: <b>${{minimalPayout}}</b></p>
                        </div>
                        <v-divider></v-divider>
                        <div 
                          class="pa-6"
                          v-bind:class="{ opacity03: userinfo.balance < minimalPayout }">
                          <p class="balance-output__text">
                            To withdraw your money, please enter your PayPal email. <br>
                            WARNING: Payments cannot be reversed. Please be sure you enter the correct PayPal email!
                          </p>
                          <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            @submit="validate"
                          >

                            <v-text-field
                              class="input-style font-size-input text-light-input placeholder-light"
                              dense
                              flat
                              filled
                              solo
                              height="43"
                              v-model="email"
                              :rules="emailRules"
                              label="Your email"
                              required
                              ref="transaction_email"
                              :disabled="(userinfo.balance) < minimalPayout"
                            ></v-text-field>
                            <div class="d-flex justify-center">
                              <v-btn
                                :disabled="!valid || (userinfo.balance < minimalPayout)"
                                color="#43A4FF"
                                dark
                                class="mr-4 text-transform-none"
                                @click="validate"
                              >
                                Request Payout
                              </v-btn>
                            </div>
                          </v-form>
                        </div>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                    v-model="dialog2"
                    width="500">      
                      <v-card>
                        <v-card-title class="text-h2 font-weight-600 py-5 px-6 text-main lighten-2">
                          Request payout
                        </v-card-title>
                        <v-divider></v-divider>
                        <div class="pt-6 px-6 pb-10">
                          <img class="d-block mx-auto mb-6" :src="approvedImg" width="56" height="56" alt="Approved">
                          <h2 class="text-center text-main mb-4 text-h3">Your request is approved</h2>
                          <p class="mb-8 text-main">
                            Your request to withdraw <b>{{formatCurrency7(lastTransaction.amount)}}</b> to <b>{{lastTransaction.email}}</b> is approved. We will make the payment within 1 week when we do our weekly payouts. You can track the status of the payment in the transaction history page.
                          </p>
                          <div class="d-flex justify-center">
                            <v-btn
                              color="#43A4FF"
                              dark
                              class="text-transform-none"
                              @click="toTransactions"
                            >
                              Transaction history
                            </v-btn>
                          </div>
                        </div>
                      </v-card>

                    </v-dialog>
                  </div>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          class="d-flex align-stretch"
          cols="12"
          md="6">
          <bandwidth-chart :chart-data="trafficChartData"></bandwidth-chart>
        </v-col>
      </v-row>
      <v-row v-if="!showCongratulations">
        <v-col
          cols="12"
          md="6"
        >
          <v-card class="card-shadow bg-gradient-primary">
            <v-card-text class="card-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-white text-uppercase text-h5"
                  >
                    Payout
                  </div>
                  <p class="font-weight-600 text-h2 text-white mb-0">
                    {{formatCurrency7(userinfo.balance)}} / {{minimalPayout}}$
                  </p>
                </v-col>
                <v-col class="text-right">
                </v-col>
              </v-row>

              <v-progress-linear
                class="progress-shadow my-4"
                :value="(((userinfo.balance) / minimalPayout) * 100)"
                rounded
                background-color="#e9ecef"
                color="#2dce89"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-card class="card-shadow bg-gradient-info">
            <v-card-text class="card-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-white text-uppercase text-h5"
                  >
                    Shared Internet
                  </div>
                  <p class="font-weight-600 text-h2 text-white mb-0">
                    {{formatTraffic(userinfo.trafficBalance)}} / 100 Gb
                  </p>
                </v-col>
                <v-col class="text-right">
                </v-col>
              </v-row>

              <v-progress-linear
                class="progress-shadow my-4"
                :value="userinfo.trafficBalance/Math.pow(1024, 3)"
                rounded
                background-color="#e9ecef"
                color="#2dce89"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          cols="12"
        >
          <v-card class="card-achieve card-shadow">
            <v-card-text class="card-padding">
              <v-row no-gutters>
                <v-col>
                  <div class="d-flex flex-wrap">
                    <div class="pr-6">
                      <img width="210" height="77" alt="Congratulations!" :src="cardAchieveImg"></img>
                    </div>
                    <div>
                      <div
                        class="font-weight-600 text-white text-h1 pb-3"
                      >
                        Congratulations!
                      </div>
                      <p class="font-weight-500 text-h3 text-white mb-0">
                        You have shared enough internet and earned the amount available for withdrawal.
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <devices-table v-bind:devices="userinfo.devices"></devices-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BandwidthChart from "@/components/Charts/BandwidthChart.vue";
import DevicesTable from "./Widgets/DevicesTable.vue";
import { getTrafficChartDataForLastMonth, getEarningsData, getUserInfo, createPayout } from "@/data.js";

export default {
  name: "Dashboard",
  data() {
    return {
      dialog: false,
      dialog2: false,
      cardAchieveImg: require("@/assets/img/achieve.svg"),
      approvedImg: require("@/assets/img/approved.svg"),
      valid: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],

      lastTransaction: {
        amount: 0,
        email: '',
      },

      trafficChartData: [
        //{
        //  x: 0, //timestamp
        //  y: 0, //value bytes
        //}
      ],

      minimalPayout: 10,
    }
  },
  computed: {
    userId: function() {
      return window.appData.currentUserId;
    },
    userinfo: function() {
      return window.appData.currentUserInfo;
    },
    showCongratulations: function() {
      //return (this.userinfo.balance >= this.minimalPayout) && (this.userinfo.trafficToday >= 100);
      return (this.userinfo.balance >= this.minimalPayout);
    },
  },
  components: {
    DevicesTable,
    BandwidthChart,
  },
  methods: {
    formatCurrency7(value) {
      if (value == 0) return `$ 0.0000000`;
      return `$ ${value.toFixed(7)}`;
    },
    formatCurrency2(value) {
      if (value == 0) return `$ 0.00`;
      return `$ ${value.toFixed(2)}`;
    },
    formatTraffic(value) {
      if (value > 1024 * 1024 * 1024) return `${(value / 1024 / 1024 / 1024).toFixed(0)} GB`;
      if (value > 1024 * 1024) return `${(value / 1024 / 1024).toFixed(0)} MB`;
      if (value > 1024) return `${(value / 1024).toFixed(0)} KB`;
      return `${value.toFixed(0)} B`;
    },
    validate () {
      event.preventDefault();
      this.$refs.form.validate();
      if(this.$refs.form.validate()) {
        this.lastTransaction.email = this.$refs.transaction_email.value;
        this.lastTransaction.amount = this.userinfo.balance;

        (async () => {
          await createPayout(this.$refs.transaction_email.value, this.userinfo);
          this.dialog = false;
          this.dialog2 = true;

          // createPayout function does not mutate local state so after popup
          // is shown user will have the ability to close popup and stay
          // at current page without causing data reload by clicking at any
          // place outside of popup. In this situation $ and traffic balance
          // will stay the same and user will have the ability to request
          // payout again, and again, ...
          //
          // To avoid that we can request userinfo again but it is not
          // instant. So I decided to mutate local state manually and pretend
          // that we have updated data that correspond to expected from backend
          // --- same as before payout but only balances are zeroed out.
          //
          //    Oleksii Moskal
          window.appData.currentUserInfo.balance = 0;
          window.appData.currentUserInfo.trafficBalance = 0;
        })();
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    toTransactions () {
      this.dialog2 = false;
      if (this.$route.meta.isAdminAsUserPage) {
        this.$router.push({name: 'AdminAsUserTransactions', params: this.$route.params});
      } else {
        this.$router.push({name: 'Transactions'});
      }
    }
  },
  async created() {
    let asyncTasks = [
      //This was offloaded to router hooks
      //(async () => { window.appData.currentUserInfo = this.userinfo = await getUserInfo(this.userId)})(),

      (async () => { this.trafficChartData = await getTrafficChartDataForLastMonth(this.userId)})(),
    ];

    await Promise.all(asyncTasks);
  },
};
</script>
