<template>
  <v-card class="card-shadow flex-grow-1">
    <div class="card-header-padding card-border-bottom">
      <p class="font-weight-600 text-typo text-h3 mb-0">Bandwidth <span class="text-muted">in {{units}}</span></p>
    </div>
    <v-card-text class="card-padding">
      <div class="chart-area">
        <canvas :height="300" style="width: 100%"></canvas>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import Chart from "chart.js/auto";

const chartConfig = {
  type: "bar",
  data: {
    //labels: dateArrayAll,
    datasets: [
      {
        label: "Total traffic",
        tension: 0.4,
        borderWidth: 0,
        borderRadius: 100,
        backgroundColor: "#fb6340",
        //data: valuesAll,
        maxBarThickness: 10,
        borderSkipped: false,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
      },
    },

    scales: {
      y: {
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          zeroLineColor: "#dee2e6",
          drawBorder: false,
          drawTicks: false,
          lineWidth: 1,
          zeroLineWidth: 1,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
        },
        ticks: {
          padding: 10,
          fontSize: 13,
          color: "#8898aa",
          font: "Open Sans",
          callback: function (val, index) {
            // Hide the label of every 2nd dataset
            return index % 2 === 0 ? this.getLabelForValue(val) : "";
          },
        },
      },
      x: {
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: true,
        },
        ticks: {
          padding: 20,
          color: "#8898aa",
          font: "Open Sans",
        },
      },
    },
  },
};

export default {
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
      units: 'GB',
    };
  },
  watch: {
    chartData: function(newData/*, oldData*/) {
      let maxTraffic = 0;
      let divider = 1;

      let data = [];
      for (let item of newData) {
        data.push({
          x: new Date(item.x),
          y: item.y,
        });
      }

      if(data.length < 30) {
        let today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth();
        let date = today.getDate();

        for(let i = 1; i <= 30; i++){
          let day = new Date(year, month - 1, date + i);

          let isDay = false;
          for(let j = 0; j < data.length; j++) {
            if(data[j].x.getDate() == day.getDate() && data[j].x.getMonth() == day.getMonth()) {
              isDay = true
            }
          }
          if(!isDay) {
            let obj = {
              "x": day,
              "y": 0
            }
            data.push(obj);
          }
        }
      }

      data.sort(function(a,b){
        return new Date(a.x) - new Date(b.x);
      });

      while(data.length > 30) {
        data.shift();
      }

      let newLabels = [];
      for (let i = 0; i < data.length; ++i) {
        let el = data[i];
        let date = el.x;
        if (maxTraffic < el.y) maxTraffic = el.y;
        newLabels.push(date.getDate() + ' ' + date.toLocaleString('en-us', { month: 'short' }))
      }

      if (maxTraffic > 5 * 1024 * 1024 * 1024) {
        this.units = 'GB';
        divider = 1024 * 1024 * 1024;
      } else if (maxTraffic > 1024 * 1024 * 1024) {
        this.units = 'MB';
        divider = 1024 * 1024;
      } else if (maxTraffic > 1024 * 1024) {
        this.units = 'KB';
        divider = 1024;
      } else if (maxTraffic > 0) {
        this.units = 'Bytes';
      } else {
        this.units = 'GB';
      }

      for (let i = 0; i < data.length; ++i) {
        data[i].y = Math.floor(data[i].y / divider);
      }

      this.chart.data.labels = newLabels;
      this.chart.data.datasets[0].data = data;
      this.chart.update();
    }
  },
  mounted() {
    let canvas = this.$el.querySelector('canvas');
    this.chart = new Chart(canvas, chartConfig);
  },
  beforeDestroy() {
    this.chart.destroy();
  },
};
</script>
